import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    Plot: westack {
      tool(id: "plot") {
        ...ToolInfo
      }
    }
    Mailgun: westack {
      tool(id: "mailgun") {
        ...ToolInfo
      }
    }
    Tableplus: westack {
      tool(id: "tableplus") {
        ...ToolInfo
      }
    }
    Open_Source_Insights: westack {
      tool(id: "open-source-insights") {
        ...ToolInfo
      }
    }
    Tengine: westack {
      tool(id: "tengine") {
        ...ToolInfo
      }
    }
    WiFi_Card: westack {
      tool(id: "wifi-card") {
        ...ToolInfo
      }
    }
    Rush_Stack: westack {
      tool(id: "rush-stack") {
        ...ToolInfo
      }
    }
    Porter: westack {
      tool(id: "porter") {
        ...ToolInfo
      }
    }
    Can_I_Email: westack {
      tool(id: "can-i-email") {
        ...ToolInfo
      }
    }
    AirTest: westack {
      tool(id: "airtest") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.Plot.tool} mdxType="Tool" />
    <Tool tool={props.data.Mailgun.tool} mdxType="Tool" />
    <Tool tool={props.data.Tableplus.tool} mdxType="Tool" />
    <Tool tool={props.data.Open_Source_Insights.tool} mdxType="Tool" />
    <Tool tool={props.data.Tengine.tool} mdxType="Tool" />
    <Tool tool={props.data.WiFi_Card.tool} mdxType="Tool" />
    <Tool tool={props.data.Rush_Stack.tool} mdxType="Tool" />
    <Tool tool={props.data.Porter.tool} mdxType="Tool" />
    <Tool tool={props.data.Can_I_Email.tool} mdxType="Tool" />
    <Tool tool={props.data.AirTest.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      